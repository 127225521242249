import {
  activeTradesActions,
  activeTradesMutations,
  activeTradesGetters
} from './types'
import { LIST_PAGE_LIMIT } from '../constants'

import { sdk } from 'Services/shelfNetworkSdk'

import { userGetters } from 'Store/entities/User/types'
import { rootGet } from 'Store/helpers/rootHelpers'

import omitBy from 'lodash/omitBy'
import { LoneSdkCall } from 'Utils/LoneSdkCall'

import { Winner } from 'Models/Winner'
import { config } from '@/config'

const loneSdkCall = new LoneSdkCall()

export default {
  async [activeTradesActions.INIT_TRADE_REPLACEMENT] ({ commit }, activeTrade) {
    commit(activeTradesMutations.SET_TRADE_TO_REPLACE, activeTrade)
  },

  async [activeTradesActions.LOAD_LOTS_ACTIVE] ({ commit }, payload = {}) {
    commit(activeTradesMutations.SET_IS_LOADING, true)

    payload.include = [
      'participations',
      'participations.deposits',
      'participations.brokers',
      'participations.brokers.basics',
      'participations.identities',
      'participations.identities.basics',
    ].join(',')
    payload = ensureRequiredFilters(payload)
    payload = normalizePayload(payload)

    const { data, fetchNext, meta = {} } = await loneSdkCall.takeLatest(
      sdk.backOffice.v2.getLotsActive(payload)
    )

    commit(activeTradesMutations.SET_ACTIVE_TRADES, data)
    commit(activeTradesMutations.SET_TOTAL_COUNT, meta.totalCount)
    commit(activeTradesMutations.SET_FETCH_NEXT, fetchNext)
    commit(activeTradesMutations.SET_IS_LOADING, false)
  },

  async [activeTradesActions.LOAD_LOTS_APPROVAL] ({ commit }, payload = {}) {
    commit(activeTradesMutations.SET_IS_LOADING, true)

    payload.include = [
      'participations',
      'participations.deposits',
      'participations.brokers',
      'participations.brokers.basics',
      'participations.identities',
      'participations.identities.basics',
    ].join(',')
    payload = ensureRequiredFilters(payload)
    payload = normalizePayload(payload)

    const { data, fetchNext, meta = {} } = await loneSdkCall.takeLatest(
      sdk.backOffice.v2.getLotsAwaitingApproval(payload)
    )

    commit(activeTradesMutations.SET_ACTIVE_TRADES, data)
    commit(activeTradesMutations.SET_TOTAL_COUNT, meta.totalCount)
    commit(activeTradesMutations.SET_FETCH_NEXT, fetchNext)
    commit(activeTradesMutations.SET_IS_LOADING, false)
  },

  async [activeTradesActions.LOAD_PARTICIPATIONS_LOST] (
    { commit }, payload = {}
  ) {
    commit(activeTradesMutations.SET_IS_LOADING, true)

    payload.include = [
      'lots',
      'deposits',
      'winners',
      'brokers',
      'brokers.basics',
      'identities',
      'identities.basics',
    ].join(',')
    payload = ensureRequiredFilters(payload)
    payload = normalizePayload(payload)

    const { data, fetchNext, meta = {} } = await loneSdkCall.takeLatest(
      sdk.backOffice.v2.getParticipationsLost(payload)
    )

    commit(activeTradesMutations.SET_ACTIVE_TRADES, data)
    commit(activeTradesMutations.SET_TOTAL_COUNT, meta.totalCount)
    commit(activeTradesMutations.SET_FETCH_NEXT, fetchNext)
    commit(activeTradesMutations.SET_IS_LOADING, false)
  },

  async [activeTradesActions.LOAD_MORE] ({ commit, getters }) {
    const fetchNext = getters[activeTradesGetters.FETCH_NEXT]
    if (!fetchNext) return

    commit(activeTradesMutations.SET_IS_NEXT_LOADING, true)

    const { data, fetchNext: newFetchNext } = await fetchNext()
    commit(activeTradesMutations.PUSH_ACTIVE_TRADES, data)
    commit(activeTradesMutations.SET_FETCH_NEXT, newFetchNext)

    commit(activeTradesMutations.SET_IS_NEXT_LOADING, false)
  },

  async [activeTradesActions.SET_LOT_WINNER] ({ commit }, payload = {}) {
    const { lotId, participationAccountId } = payload

    await sdk.buyingPower.updateWinnerDetails({
      lotId,
      accountId: participationAccountId,
      attributes: {
        fee: '0.00',
        feeCurrency: config.DEFAULT_CURRENCY,
        transportationPrice: '0.00',
        transportationPriceCurrency: config.DEFAULT_CURRENCY,
        state: Winner.statesEnum.waitingForInvoice,
      },
    })

    commit(activeTradesMutations.REMOVE_LOT, { lotId })
  },

  async [activeTradesActions.CLOSE_LOT] ({ commit }, { lotId }) {
    await sdk.buyingPower.participants.rejectAllParticipants(lotId)
    commit(activeTradesMutations.REMOVE_LOT, { lotId })
  },

  async [activeTradesActions.UPDATE_LOT_PARTICIPATION_BID] (
    { commit }, payload = {}
  ) {
    const { lotId, bid, participationAccountId } = payload

    await sdk.buyingPower.participants.setBid({
      lotId,
      accountId: participationAccountId,
      amount: String(bid)
    })

    commit(activeTradesMutations.SET_LOT_PARTICIPATION_BID,
      { bid, lotId, participationAccountId },
    )
  },

  async [activeTradesActions.UPDATE_LOT_PARTICIPATION_BID_LIMIT] (
    { commit },
    payload = {},
  ) {
    const { lotId, bidLimit, participationAccountId } = payload

    await sdk.buyingPower.participants.setBidLimit({
      lotId,
      accountId: participationAccountId,
      amount: String(bidLimit)
    })

    commit(activeTradesMutations.SET_PARTICIPATION_BID_LIMIT,
      { bidLimit, lotId, participationAccountId },
    )
  },

  async [activeTradesActions.UPDATE_LOT_PARTICIPATION_AUTO_BID_LIMIT] (
    { commit },
    payload = {},
  ) {
    const { lotId, autoBidLimit, participationAccountId } = payload

    await sdk.buyingPower.participants.setAutoBidLimit({
      lotId,
      accountId: participationAccountId,
      amount: String(autoBidLimit)
    })

    commit(activeTradesMutations.SET_PARTICIPANT_AUTO_BID_LIMIT,
      { autoBidLimit, lotId, participationAccountId },
    )
  },

  async [activeTradesActions.REJECT_LOT_PARTICIPATION] (
    { commit, getters }, payload = {}
  ) {
    const { lotId, participationAccountId } = payload

    await sdk.buyingPower.participants.rejectParticipant(
      lotId,
      participationAccountId
    )

    const activeTrade = getters[activeTradesGetters.ACTIVE_TRADES]
      .find(item => {
        return item.lot.id === lotId && item.participations
          .find(el => el.accountId === participationAccountId)
      })
    if (!activeTrade) return

    if (activeTrade.knownParticipations.length > 1) {
      commit(activeTradesMutations.REMOVE_LOT_PARTICIPATION,
        { lotId, participationAccountId },
      )
    } else {
      commit(activeTradesMutations.REMOVE_LOT, { lotId })
    }
  },
}

function ensureRequiredFilters (payload = {}) {
  const result = Object.assign({}, payload)
  result.filter = Object.assign({}, payload.filter)
  result.page = Object.assign({}, payload.page)
  result.page.limit = LIST_PAGE_LIMIT

  if (rootGet(`entities/user/${userGetters.IS_BROKER}`)) {
    result.filter.broker = result.filter.broker ||
      rootGet(`entities/user/${userGetters.ACCOUNT_ID}`)
  } else {
    result.filter.platforms = result.filter.platforms ||
      rootGet(`entities/user/${userGetters.PLATFORM_ID}`)
  }

  return result
}

function normalizePayload (payload) {
  const result = Object.assign({}, payload)
  result.page = Object.assign({}, payload.page)
  result.page.limit = LIST_PAGE_LIMIT

  return omitBy(result, val => !val)
}
