export function genActiveTradesState () {
  return {
    isLoading: true,
    isNextLoading: false,
    activeTrades: [],
    totalCount: 0,
    fetchNext: null,
    tradeToReplace: null
  }
}

export default genActiveTradesState
