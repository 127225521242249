export const activeTradesActions = {
  LOAD_LOTS_ACTIVE: 'LOAD_LOTS_ACTIVE',
  LOAD_LOTS_APPROVAL: 'LOAD_LOTS_APPROVAL',
  LOAD_PARTICIPATIONS_LOST: 'LOAD_PARTICIPATIONS_LOST',
  LOAD_MORE: 'LOAD_MORE',
  UPDATE_LOT_PARTICIPATION_BID: 'UPDATE_LOT_PARTICIPATION_BID',
  UPDATE_LOT_PARTICIPATION_BID_LIMIT: 'UPDATE_LOT_PARTICIPATION_BID_LIMIT',
  UPDATE_LOT_PARTICIPATION_AUTO_BID_LIMIT: 'UPDATE_LOT_PARTICIPATION_AUTO_BID_LIMIT',
  REJECT_LOT_PARTICIPATION: 'REJECT_LOT_PARTICIPATION',
  SET_LOT_WINNER: 'SET_LOT_WINNER',
  CLOSE_LOT: 'CLOSE_LOT',
  INIT_TRADE_REPLACEMENT: 'INIT_TRADE_REPLACEMENT',
  REPLACE_TRADE: 'REPLACE_TRADE',
}

export const activeTradesMutations = {
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_IS_NEXT_LOADING: 'SET_IS_NEXT_LOADING',
  SET_ACTIVE_TRADES: 'SET_ACTIVE_TRADES',
  PUSH_ACTIVE_TRADES: 'PUSH_ACTIVE_TRADES',
  SET_TOTAL_COUNT: 'SET_TOTAL_COUNT',
  SET_FETCH_NEXT: 'SET_FETCH_NEXT',
  SET_LOT_PARTICIPATION_BID: 'SET_LOT_PARTICIPATION_BID',
  SET_PARTICIPATION_BID_LIMIT: 'SET_PARTICIPATION_BID_LIMIT',
  SET_PARTICIPANT_AUTO_BID_LIMIT: 'SET_PARTICIPANT_AUTO_BID_LIMIT',
  REMOVE_LOT_PARTICIPATION: 'REMOVE_LOT_PARTICIPATION',
  REMOVE_LOT: 'REMOVE_LOT',
  FORCE_TOP_PARTICIPATION: 'FORCE_TOP_PARTICIPATION',
  UPDATE_LEAD_BROKER: 'UPDATE_LEAD_BROKER',
  SET_TRADE_TO_REPLACE: 'SET_TRADE_TO_REPLACE'
}

export const activeTradesGetters = {
  IS_LOADING: 'IS_LOADING',
  IS_NEXT_LOADING: 'IS_NEXT_LOADING',
  ACTIVE_TRADES: 'ACTIVE_TRADES',
  ACTIVE_TRADES_COUNT: 'ACTIVE_TRADES_COUNT',
  FETCH_NEXT: 'FETCH_NEXT',
  TRADE_TO_REPLACE: 'TRADE_TO_REPLACE',
}
