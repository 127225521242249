import { ActiveTrade } from 'Models/ActiveTrade'
import { activeTradesGetters } from './types'

export default {
  [activeTradesGetters.IS_LOADING]: state => state.isLoading,
  [activeTradesGetters.IS_NEXT_LOADING]: state => state.isNextLoading,
  [activeTradesGetters.ACTIVE_TRADES]:
    state => state.activeTrades.map(item => new ActiveTrade(item)),
  [activeTradesGetters.ACTIVE_TRADES_COUNT]: state => state.totalCount || 0,
  [activeTradesGetters.FETCH_NEXT]: state => state.fetchNext,
  [activeTradesGetters.TRADE_TO_REPLACE]: state => state.tradeToReplace,
}
