import { ModelBase } from './ModelBase'
import { Lot } from './Lot'
import { Participation } from './Participation'

export class ActiveTrade extends ModelBase {
  constructor (raw = {}, cache = {}) {
    // HACK: don't pass cache to prevent cache poisoning
    // ActiveTrade has resourceType lots for some reason
    super(raw)

    this.lot = ModelBase.modelFromCache(Lot, raw.lot, cache)
    this.participations = raw.participations
      .map(el => ModelBase.modelFromCache(Participation, el, cache))
      .sort((elA, elB) => Boolean(elB.email) - Boolean(elA.email))
      .sort((elA, elB) => elB.offer - elA.offer)
    this.knownParticipations = this.participations
      .filter(el => el.isControllable)
    this.topParticipation = this._getTopParticipation()
  }

  _getTopParticipation () {
    const participations = this.knownParticipations.length > 0
      ? this.knownParticipations
      : this.participations

    const forcedResult = participations.find(el => el.isForcedToTop)
    if (forcedResult) return forcedResult

    const topParticipation = participations.reduce(
      (prev, cur) => {
        return Number(cur.offer) > Number(prev.offer)
          ? cur
          : prev
      },
      participations[0] || new Participation({}),
    )

    const userParticipation = participations.find(item => {
      return item.broker.id === topParticipation.accountId
    })

    return userParticipation || topParticipation
  }
}
