import Vue from 'vue'
import { activeTradesMutations } from './types'

export default {
  [activeTradesMutations.SET_IS_LOADING] (state, value) {
    state.isLoading = value
  },

  [activeTradesMutations.SET_IS_NEXT_LOADING] (state, value) {
    state.isNextLoading = value
  },

  [activeTradesMutations.SET_ACTIVE_TRADES] (state, value) {
    state.activeTrades = normalizeActiveTrades(value)
  },

  [activeTradesMutations.PUSH_ACTIVE_TRADES] (state, value) {
    state.activeTrades = state.activeTrades
      .concat(normalizeActiveTrades(value))
  },

  [activeTradesMutations.SET_TOTAL_COUNT] (state, value) {
    state.totalCount = value
  },

  [activeTradesMutations.SET_FETCH_NEXT] (state, value) {
    state.fetchNext = value
  },

  [activeTradesMutations.SET_LOT_PARTICIPATION_BID] (state, payload = {}) {
    const { lotId, participationAccountId: accId, bid } = payload

    const { lot, participation } =
      getLotParticipation(state.activeTrades, lotId, accId)
    if (!lot || !participation) return

    Vue.set(participation, 'currentBid', Number(bid).toFixed(2))
    if (Number(bid) > Number(lot.highestBid)) {
      Vue.set(lot, 'highestBid', Number(bid).toFixed(2))
    }
  },

  [activeTradesMutations.SET_PARTICIPATION_BID_LIMIT] (state, payload = {}) {
    const { lotId, participationAccountId: accId, bidLimit } = payload

    const { participation } =
      getLotParticipation(state.activeTrades, lotId, accId)
    if (!participation) return

    Vue.set(participation, 'bidLimit', Number(bidLimit).toFixed(2))
  },

  [activeTradesMutations.SET_PARTICIPANT_AUTO_BID_LIMIT] (state, payload = {}) {
    const { lotId, participationAccountId: accId, autoBidLimit } = payload

    const { participation } =
      getLotParticipation(state.activeTrades, lotId, accId)
    if (!participation) return

    Vue.set(participation, 'autoBidLimit', Number(autoBidLimit).toFixed(2))
  },

  [activeTradesMutations.REMOVE_LOT_PARTICIPATION] (state, payload = {}) {
    const { lotId, participationAccountId: accId } = payload

    const { activeTrade, participation } =
      getLotParticipation(state.activeTrades, lotId, accId)
    if (!activeTrade || !participation) return

    const newParticipations = activeTrade.participations
      .filter(el => el.id !== participation.id)

    Vue.set(activeTrade, 'participations', newParticipations)
  },

  [activeTradesMutations.REMOVE_LOT] (state, { lotId }) {
    const index = state.activeTrades.findIndex(el => el.lot.id === lotId)
    Vue.delete(state.activeTrades, index)
  },

  [activeTradesMutations.FORCE_TOP_PARTICIPATION] (state, payload = {}) {
    const { lotId, participationAccountId: accId } = payload

    const { activeTrade, participation } =
      getLotParticipation(state.activeTrades, lotId, accId)
    if (!activeTrade || !participation) return

    const newParticipations = activeTrade.participations
      .map(el => {
        el.isForcedToTop = el.id === participation.id
        return el
      })

    Vue.set(activeTrade, 'participations', newParticipations)
  },

  [activeTradesMutations.UPDATE_LEAD_BROKER] (state, payload) {
    const { leadId, broker } = payload

    for (const activeTrade of state.activeTrades) {
      for (const participation of activeTrade.participations) {
        const lead = (participation.relationships || {}).lead || {}
        if (lead.id === leadId) {
          const toSet = (broker || {}).id ? [broker] : []
          Vue.set(participation.relationships, 'brokers', toSet)
        }
      }
    }
  },

  [activeTradesMutations.SET_TRADE_TO_REPLACE] (state, payload) {
    state.tradeToReplace = payload
  }
}

function normalizeActiveTrades (list) {
  if (!list || !list.length) return []

  if (list[0].resourceType === 'lots') {
    return list.map(item => ({
      id: item.id,
      resourceType: item.resourceType,
      lot: item,
      participations: (item.relationships || {}).participations || [],
    }))
  }

  if (list[0].resourceType === 'participations') {
    return list.map(item => ({
      id: item.id,
      resourceType: item.resourceType,
      lot: (item.relationships || {}).lot || {},
      participations: [item],
    }))
  }

  return []
}

function getLotParticipation (activeTrades, lotId, accountId) {
  const activeTrade = activeTrades.find(el => el.lot.id === lotId)
  if (!activeTrade) {
    return { lot: undefined, participation: undefined, activeTrade: undefined }
  }

  const participation = activeTrade.participations.find(el => {
    const elAccountId = ((el.relationships || {}).identity || {}).id
    return elAccountId === accountId
  })

  return { lot: activeTrade.lot, participation, activeTrade }
}
